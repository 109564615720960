.slide img{ width: 100%;}
.stats-block{ background: url("../images/stats-bg.jpg")no-repeat center; padding: 80px 0 120px 0;}
.stats-icon {
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom-color:#eaa73e;
    position: relative;
  }
  .stats-icon:after {
    content: '';
    position: absolute;
    left: -50px;
    top: 50px;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-top-color:#eaa73e;
  }
  .stats-icon i{ position: relative; z-index: 9;color: #fff; font-size: 30px;line-height: 100px;position: relative; left: 50%;transform: translateX(-50%);-webkit-transform: translateX(-50%);}
  .stats-content{ margin: -35px 0 0 110px;color: #fff; float: left;}
  .stats-content h2{font-size: 40px;}
  .stats-content h6{ font-size: 16px;}
  .stats-content h2,.stats-content h6{color: #fff; padding: 0 0 10px 0}
  .stats-inner{ display: inline-block; width: 100%;}
  .about-images{ float: right; min-height: 450px;}
  .about-images h2{ display: none;}
  .about-images .about-img{width: 380px; height: 310px; float: right;}
  .about-images .about-img1{width: 445px; height: 420px; position: absolute; left: 0;top: 100px;}
  .about-images img{border-radius: 5px;}
  .banner-content{position: absolute;left: 0; right: 0; bottom: 50px; color: #fff;}
  .banner-content h2,.banner-content h6{color: #fff;}
  .carousel-control-next-icon, .carousel-control-prev-icon{width: 40px; height: 40px;}
  .carousel-control-next-icon{ background: url("../images/right-arrow.png")no-repeat center; }
  .carousel-control-prev-icon{ background: url("../images/left-arrow.png")no-repeat center; }
  .carousel-control-next, .carousel-control-prev{width: 10%;}

